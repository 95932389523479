import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { auth } from 'firebase/app';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from '../app.component';
import { identifierModuleUrl } from '@angular/compiler';

export interface DialogData {
  volume: string;
  data: object;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  ngOnInit(): void {
  }

  public title      = 'apollocx';
  public loggedin   = false;
  public logging_in = false;    
  public uid        = 'false';    
  public email      = 'false';    
  public user       = {};
  private admin     = false;
  private approved  = false;
  private terms_accepted = false;
  private tab_index = 0;
  private maintenance = false;

  //Auto buy
  private auto_buy = false;

  // Subscriptions
  public user_subscription = 0;
  public user_subscription_set = 0;
  public rate1 = "49";
  public rate2 = "49"; 
  public subscriptions = [
    {'id':0,'description':'Free','detail':'Real-time metrics','cost':"0"},
    {'id':1,'description':'Premium','detail':'Base functionality + Portfolio tracking, positions, algorithmic trading, automated feeless trading','cost':"49"},
    {'id':2,'description':'Premium','detail':'Base functionality + Portfolio tracking, positions, algorithmic trading, automated feeless trading','cost':"49"}
  ];
  public user_balances = [];
  public user_balance  = 0;
  public expiry_date = '';
  public balance = 0;

  // Functionality
  public func_portfolio = false;
  public func_bot = false;
  public func_trade = false;
  public func_profile = false;
  public luno_keys_set    = false;    
  public polo_keys_set    = false;    

  // Loading
  public loading = true;
  public portfolio_loaded = false;    
  public prices_loaded = false;
  public user_loaded = false;
  public performance_loaded = false;
  public welcome = true;
  public terms = false;
  
  //Line chart
  private line_colors: Color[]              = [{borderColor: 'black', backgroundColor: 'rgba(255,0,0,0.3)'}];
  private line_type                         = 'line';     
  private price_data: ChartDataSets[]       = [{ data: [], label: 'Prices'}];
  private performance_data: ChartDataSets[]       = [{ data: [], label: 'Gain'}];
  private price_data_world: ChartDataSets[] = [{ data: [], label: 'Prices'}];
  private price_data_world_eth: ChartDataSets[] = [{ data: [], label: 'Prices'}];
  private price_data_eth: ChartDataSets[]   = [{ data: [], label: 'Prices'}];
  private mi_data_eth: ChartDataSets[]      = [{ data: [], label: 'Prices'}];
  private mi_labels: Label[]                = [];
  private price_labels: Label[]             = [];
  private performance_labels: Label[]       = [];
  private price_labels_long: Label[]        = [];
  private luno_options         = {responsive: true, elements: {line: {tension: 0}}, animation: { duration: 0 }, maintainAspectRatio: false};  
  private performance_options  = {responsive: true, elements: {line: {tension: 0}}, animation: { duration: 0 }, maintainAspectRatio: false};  
  public scatterChartType: ChartType = 'scatter';

  // Trades
  private open_trades = [];
  private portfolio:any;
  private current:any;
  private positions:any;
  private profit_total = 0;
  private state:any;
  private settings:any;
  private trade_parameters:any;
  private trade_config:any;

  // Data
  private price_luno = 0;
  private price_world = 0;
  private price_diff = 0;
  private luno_mi = 0;
  private gemini_mi = 0;
  private gemini_mi_ma = 0;
  private p_gain = 0;
  private gemini_mi_threshold = 1.3;
  private orders = [];
  private ZAR_volume = 0;
  private USDZAR = 0;


  //Api keys
  private api_luno_key = '';
  private api_luno_sec = '';
  private api_polo_key = '';
  private api_polo_sec = '';
  private feedback = '';

  //Positions
  private positions_closed = [];
  private positions_open = [];
  private positions_waiting = [];
  private performance = [];
  private position_submitted = false;
  private positions_vol = 0;
  private copy = {};
  private copy_list = [];
  private my_performance:any;
  private my_performance_loaded=false;
  private positions_index=0;

  //credit
  private credit_UID = 'I3qKlk2IzRdfHIOgDZb3FMe0xGk2';
  private credit_ZAR = 0;
  private credit_balances = [];

  constructor(public app: AppComponent, public dialog: MatDialog, public http:HttpClient, public authenticate: AngularFireAuth, public firestore: AngularFirestore, private _snackBar: MatSnackBar) {

    this.open_trades = [];
    this.portfolio = {
      "balance_btc_luno":[0,0],
      "balance_zar_luno":[0,0]
    };
    this.current = {
      "differences":{"btc":0},
      "luno_btc":{"price":0},
      "fx":{"USD_ZAR":0}
    };
    this.positions = {
      "open_positions_count":0
    };
    this.profit_total = 0;
    this.state = {
      "take_profit":0,
      "margin_sl":0,
    };
    this.settings = {
      "threshold_lower":0,
      "threshold_upper":0,
    };
    this.trade_parameters = {
      'currency':'XBT',
      'volume_zar':1000,
      'volume_btc':0.0,
      'buy':true,
      'sell':false,
      'volume_all':false,
      'complete':false,
      'placed':false,
      'id':'',
      'key':'',
      'price':0,
      'status':'',
      'pair':'XBTZAR',
      'market_order':false,
      'time_requested':0,
      'time_finished':0,
      'fill':0,
      'balance_start':0,
      'balance_end':0,
      'last_attended':(new Date()).getTime(),
      'stop':false,
      'orders':{},
      'set_XBT':false,
      'immediate':0,
      'auto_sell':0,
      'stoploss':-1,
      'profittake':2,
      'mi_enter':1.05,
      'mi_exit':0.95,
      'enter_at':0,
      'track_position':false 
    }    
    this.trade_config = {
      'profittake':2,
      'stoploss':1,
      'market':false,
      'status':0,
      'volume':1000,
      'immediate':true
    }
    
    //Get authentication
    this.authenticate.authState.subscribe((user) => {      
      if(user){ 
        if(!user.isAnonymous){       
          this.loggedin = true;
          this.logging_in = false;   
          this.uid = user.uid; 
          this.email = user.email;
          this.user = user; 
          this.load_user();
          this.loading = true;
          if(this.uid == 'I3qKlk2IzRdfHIOgDZb3FMe0xGk2'){this.admin=true};
        } else {
          this.loggedin = false;
        }
      } else {
        this.logging_in = false; this.loggedin = false; this.loading = false; this.terms_accepted = false; this.approved = false;
      };      
    }) 

  }  

  load_user(){
    this.firestore.collection("users").doc(this.uid).snapshotChanges().subscribe(user => {
      if(this.loggedin){
        if(user.payload.data() == undefined){          
          this.app.home();
        } else {

          this.user = user.payload.data(); 
          
          if(this.user.hasOwnProperty('terms')){
            this.terms_accepted = this.user['terms'];  
          } else {
            this.firestore.collection("users").doc(this.uid).update({'terms':false});
          }
          
          if(this.terms_accepted){
            this.load_data()
          } else {this.app.onboard()}

          this.luno_keys_set = this.user['keys_luno'];
          this.polo_keys_set = this.user['keys_polo'];

          this.user_loaded = true; 

        }
      }
    })
  }    

  snack(message: string) {
    this._snackBar.open(message,"", {duration: 3000});
  }
  
  snack_long(message: string) {
    this._snackBar.open(message,"", {duration: 8000});
  }  

  toggle_auto_buy(){
    this.firestore.collection('auto_buy').doc(this.uid).set({
      'status':this.auto_buy,
      'uid':this.uid,
      'time':(new Date()).getTime()
    }).then(d => {
      if(this.auto_buy){
        this.snack("Auto-buy activated");
      } else {
        this.snack("Auto-buy deactivated");
      }
    })    
  }

  store_keys(exchange){

    if(exchange == 'LUNO'){
      this.firestore.collection('tasks').add({
        'key':this.api_luno_key,
        'sec':this.api_luno_sec,
        'uid':this.uid,
        'exchange':'luno',
        'type':'keys',
        'attended':0
      }).then(d => {
        this.snack("Keys submitted");
        this.api_luno_key = '';
        this.api_luno_sec = '';
      })
    }

    if(exchange == 'POLO'){
      this.firestore.collection('tasks').add({
        'key':this.api_polo_key,
        'sec':this.api_polo_sec,
        'uid':this.uid,
        'exchange':'polo',
        'type':'keys',
        'attended':0
      }
      ).then(d => {
        this.snack("Keys submitted");
        this.api_polo_key = '';
        this.api_polo_sec = '';        
      })
    }    

  }

  info(){
    this.snack_long('ApolloCX tracks demand and supply dynamics in real time on various exchanges across the world. A ratio of > 1 means that demand is estimated to exceed supply.');
  }

  update_displayname(new_name){
    this.firestore.collection("users").doc(this.uid).update({'displayname':new_name});    
  }
  
  info_world(){
    this.snack_long('ApolloCX tracks prices in real time on various exchanges across the world and calculates an average price which is then adjusted to ZAR using the current exchange rate.');
  }  

  // Trade functions
  set_volume(currency){
    if(currency === 'XBT'){
      if(this.trade_parameters['immediate'] == 0){
        this.trade_parameters['volume_btc'] = Math.round(1000000*this.trade_parameters.volume_zar/this.price_luno)/1000000;
      }
      if(this.trade_parameters['immediate'] == 1){
        this.trade_parameters['volume_btc'] = Math.round(1000000*this.trade_parameters.volume_zar/this.trade_parameters.enter_at)/1000000;
      }
      if(this.trade_parameters['immediate'] == 2){
        this.trade_parameters['volume_btc'] = Math.round(1000000*this.trade_parameters.volume_zar/this.price_luno)/1000000;
      }      
    }
    if(currency === 'ZAR'){
      this.ZAR_volume = this.price_luno*this.trade_parameters['volume'];
    }
  }

  stop(key){
    this.firestore.collection('tasks').add({
      'uid':this.uid,
      'type':'stop_order',
      'key':key,
      'attended':0
    }).then(d => {
      this.snack("Order will be stopped");
    })    
  }

  trade(){
    var pass = true;
    if(this.trade_parameters.volume_btc < 0.0005){
      this.snack('Trade must be larger than BTC 0.0005');
      pass=false;
    };
    if(this.trade_parameters.profittake < 0.25){
      this.snack('Profit take must be > 0.25%');
      pass=false;
    }
    if(this.trade_parameters.stoploss > -0.25){
      this.snack('Stop loss take must be < -0.25%');
      pass=false;
    }    
    if(this.user_subscription == 0){
      this.snack('Premium subscription required');
      this.tab_index = 3;
      pass=false;      
    }
    if(this.trade_parameters.immediate == 1 && this.trade_parameters.enter_at >= this.price_luno){
      this.snack('Select a lower entry price');
      pass=false;      
    }  
    if(!this.luno_keys_set){
      this.snack('Please check Luno API keys');
      pass=false;       
    }
    if(this.uid == 'false'){
      this.snack('Account error - contact support');
      pass=false;       
    }    
    if(pass){
      this.trade_parameters['type']   = 'new_trade';
      this.trade_parameters['uid']    = this.uid;
      this.trade_parameters['sell']   = !this.trade_parameters['buy'];
      this.trade_parameters['pair']   = this.trade_parameters['currency']+'ZAR';
      this.trade_parameters['price']  = this.trade_parameters['enter_at'];
      this.trade_parameters['status'] = 'WAITING';
      this.trade_parameters['attended'] = 0;
      this.firestore.collection('tasks').add(this.trade_parameters).then(d => {
        
        this.snack('Trade Submitted');        
        if(this.trade_parameters['track_position']){
          this.tab_index = 2;
          this.trade_parameters['track_position'] = false;
        } else {
          this.tab_index = 1;
          this.trade_parameters['track_position'] = false;
        }

      });
    }    
  }  

  validate_enter(){
    if(this.trade_parameters.enter_at >= this.price_luno){
      this.snack('Entry price must be lower than current price');
      this.trade_parameters.enter_at = this.price_luno - 5000;
    }
  }

  validate_ptsl(){
    if(this.trade_parameters.profittake < 0.25){
      this.snack('Profit take must be greater than 0.25%');
      this.trade_parameters.profittake = 0.25;
    }
    if(this.trade_parameters.stoploss > -0.25){
      this.snack('Stop loss must be lower than -0.25%');
      this.trade_parameters.stoploss = -0.25;
    }    
  }

  validate_thresholds(){
    if(this.trade_parameters.mi_enter*1 - this.trade_parameters.mi_exit*1 < 0.1){
      this.snack('Exit threshold must be 0.1 or more lower than entry threshold');
      this.trade_parameters.mi_exit = this.trade_parameters.mi_enter*1-0.1;
    }
  }

  change_subscription(){  
    
    if(!this.luno_keys_set){    
      console.log(this.user_subscription_set);
      this.snack("Please set keys");
      this.user_subscription = 0;
      this.user_subscription_set = 0; 
      console.log(this.user_subscription_set);   
    } else {
      if(this.user_subscription_set > 0 && this.balance <= 0){
        this.snack("Positive account balance required");
        this.user_subscription = 0;
        this.user_subscription_set = 0;         
      } else {      
        console.log("Updating subscription from",this.user_subscription,"to",this.user_subscription_set);
        this.firestore.collection('tasks').add({
          'from':this.user_subscription,
          'to':this.user_subscription_set,
          'uid':this.uid,
          'type':'subscription_update',
          'attended':0
        }).then(d => {
          console.log("Subcription update sent");
        })   
      }   
   
    }

  }  

  get_balances(){
    this.firestore.collection("balances", ref => ref.where("uid","==",this.uid).orderBy("time","desc").limit(5)).snapshotChanges().subscribe(balances => {
      this.user_balances = [];
      if(balances.length === 0){
        console.log('Waiting for balance ...')
      } else {
        balances.forEach(b => {this.user_balances.push(b.payload.doc.data())});
        this.user_balances = this.user_balances.reverse();
        var balance = parseFloat(this.user_balances[this.user_balances.length-1]['balance']);
        var time = parseFloat(this.user_balances[this.user_balances.length-1]['time']);
        var rate = 0;
        var description = "";
        if(this.user_subscription == 0){rate = 0; description = "Zero cost for free subscription"};
        if(this.user_subscription == 1){rate = parseFloat(this.rate1); description = "Cost for Base subscription"};
        if(this.user_subscription == 2){rate = parseFloat(this.rate2); description = "Cost for Premium subscription"};
        var out = rate*((new Date()).getTime() - time)/2635200000; //=1000*60*60*24*30.5;
        this.balance = balance - out;
        console.log(this.balance);
        if(balance - out > 0 && rate > 0){
          this.expiry_date = (new Date((new Date()).getTime() + ((balance - out)/rate)*2635200000)).toDateString();
        } else {
          if(this.user_subscription > 0){
            this.user_subscription_set = 0;
            this.snack('Account balance depleted');
            this.change_subscription();
          }; 
        }
      }
    })  
  }

  close_welcome(){
    this.welcome=false;
    this.firestore.collection("users").doc(this.uid).update({'welcome':false});
  }

  load_data(){

    // Sub
    this.firestore.collection("subscriptions", ref => ref.where("uid","==",this.uid).orderBy("time","asc").limitToLast(1)).valueChanges().subscribe(sub => {
      if(sub.length > 0){     
        this.user_subscription = sub[0]['subscription'];
        this.user_subscription_set = this.user_subscription;
        console.log("Current subscription =",this.user_subscription);
        if(this.user_subscription >= 2){
          this.func_portfolio = true;
          this.func_bot = true;
          this.func_trade = true;
        } else {
          this.func_portfolio = false;
          this.func_bot = false;
          this.func_trade = false;          
        }        
        if(this.user_balances.length === 0){
          this.get_balances();
        }
      }
    }) 

    // Parameters
    this.firestore.collection("parameters").doc("web").valueChanges().subscribe(m => {
      this.maintenance = m['maintenance'];
    }) 
    
    this.firestore.collection("auto_buy").doc(this.uid).valueChanges().subscribe(m => {
      if(m !== undefined){
        this.auto_buy = m['status'];
      }    
    })     

    // Price chart data
    this.firestore.collection("prices",ref => ref.orderBy('time').limitToLast(500)).valueChanges().subscribe(points => {
      
      this.price_labels = [];
      this.price_labels_long = [];
      
      var LUNO = [];
      var WORLD = [];
      var WORLD_LONG = [];
      var WORLD_ADJ = [];
      var LUNO_WORLD = [];      
      var MI = [];
      var MI_MA = [];

      var LUNO_ETH = [];
      var WORLD_ETH = [];
      var WORLD_LONG_ETH = [];
      var WORLD_ADJ_ETH = [];
      var LUNO_WORLD_ETH = [];      
      var MI_ETH = [];
      var MI_MA_ETH = [];      

      var counter = 1;
      var USDZAR = 0;

      var luno_min = 9999999999;
      var luno_max = 0;
      var world_min = 9999999999;
      var world_max = 0;
      
      points.forEach(p => {     
        
        if(LUNO.length > 0){
          if(Math.abs(p['BTC_LUNO']*1 - LUNO[LUNO.length-1]) > LUNO[LUNO.length-1]*0.05){
            p['BTC_LUNO'] = LUNO[LUNO.length-1]
          }
          if(Math.abs(p['BTC_WRLD']*1 - WORLD[WORLD.length-1]) > WORLD[WORLD.length-1]*0.05){
            p['BTC_WRLD'] = LUNO[LUNO.length-1]
          } 
          if(Math.abs(p['BTC_WRLD_ADJ']*1 - WORLD_ADJ[WORLD_ADJ.length-1]) > WORLD_ADJ[WORLD_ADJ.length-1]*0.05){
            p['BTC_WRLD_ADJ'] = WORLD_ADJ[WORLD_ADJ.length-1]
          }                    
        }

        if(LUNO_ETH.length > 0){
          if(Math.abs(p['ETH_LUNO']*1 - LUNO_ETH[LUNO_ETH.length-1]) > LUNO_ETH[LUNO_ETH.length-1]*0.05){
            p['ETH_LUNO'] = LUNO_ETH[LUNO_ETH.length-1]
          }
          if(Math.abs(p['ETH_WRLD']*1 - WORLD_ETH[WORLD_ETH.length-1]) > WORLD_ETH[WORLD_ETH.length-1]*0.05){
            p['ETH_WRLD'] = LUNO_ETH[LUNO_ETH.length-1]
          } 
          if(Math.abs(p['ETH_WRLD_ADJ']*1 - WORLD_ADJ_ETH[WORLD_ADJ_ETH.length-1]) > WORLD_ADJ_ETH[WORLD_ADJ_ETH.length-1]*0.05){
            p['ETH_WRLD_ADJ'] = WORLD_ADJ_ETH[WORLD_ADJ_ETH.length-1]
          }                    
        }     

        LUNO.push(p['BTC_LUNO']*1);
        WORLD.push(p['BTC_WRLD']*1);
        WORLD_ADJ.push(p['BTC_WRLD_ADJ']*1);
        LUNO_WORLD.push(p['BTC_LUNO']-p['BTC_WRLD_ADJ']);
        WORLD_LONG.push(p['BTC_WRLD']*1);
        MI.push(p['BTC_GEMI_MI']);
        MI_MA.push(p['BTC_GEMI_MI_MA'])

        LUNO_ETH.push(p['ETH_LUNO']*1);
        WORLD_ETH.push(p['ETH_WRLD']*1);
        WORLD_ADJ_ETH.push(p['ETH_WRLD_ADJ']*1);
        LUNO_WORLD_ETH.push(p['ETH_LUNO']-p['ETH_WRLD_ADJ']);
        WORLD_LONG_ETH.push(p['ETH_WRLD']*1);
        MI_ETH.push(p['ETH_GEMI_MI']);
        MI_MA_ETH.push(p['ETH_GEMI_MI_MA'])        

        USDZAR = p['USDZAR'];

        this.price_labels.push((new Date(p['time'])).toLocaleTimeString());
        this.price_labels_long.push((new Date(p['time'])).toLocaleTimeString());
        this.mi_labels.push((new Date(p['time'])).toLocaleTimeString());    
        counter+=1;  

        if(p['BTC_LUNO']*1 > luno_max){luno_max=p['BTC_LUNO']};
        if(p['BTC_LUNO']*1 < luno_min){luno_min=p['BTC_LUNO']};
        if(p['BTC_WRLD']*1 > world_max){world_max=p['BTC_WRLD']};
        if(p['BTC_WRLD']*1 < world_min){world_min=p['BTC_WRLD']};        
      });

      this.USDZAR = USDZAR;

      this.luno_options['scales'] = {'yAxes': [
        {
            id: 'yAxis1',
            position: 'right'
        },
        {
            id: 'yAxis2',
            position: 'left'
        }
        ]
      }

      // this.world_options['scales'] = {'yAxes': [{
      //     ticks: {
      //         min: Math.round(world_min*0.97/1000)*1000,
      //         max: Math.round(world_max*1.02/1000)*1000,
      //     }
      //   }]
      // }    

      this.price_luno = points[points.length-1]['BTC_LUNO'];
      this.price_world = points[points.length-1]['BTC_WRLD'];
      this.luno_mi = points[points.length-1]['BTC_LUNO_MI'];
      this.gemini_mi = points[points.length-1]['BTC_GEMI_MI'];
      this.gemini_mi_ma = points[points.length-1]['BTC_GEMI_MI_MA'];
      this.p_gain = points[points.length-1]['P_GAIN'];
      this.mi_labels = [];

      if(this.trade_parameters.enter_at == 0){this.trade_parameters.enter_at=this.price_luno};

      if(this.trade_parameters.immediate == 0){
        this.trade_parameters.enter_at = this.price_luno; 
        this.set_volume('XBT');
      }

      this.price_data_world = [{ data: WORLD, label: 'WORLD'}];
      this.price_data = [{ data: LUNO, label: 'LUNO', yAxisID: 'yAxis1'}];
      this.price_data.push({ data: WORLD_ADJ, label: 'WORLD', yAxisID: 'yAxis1'});      
      this.price_data.push({ data: MI, label: 'MI', yAxisID: 'yAxis2'});      
      this.price_data.push({ data: MI_MA, label: 'MI MA', yAxisID: 'yAxis2'});      

      this.price_data_world_eth = [{ data: WORLD_ETH, label: 'WORLD'}];
      this.price_data_eth = [{ data: LUNO_ETH, label: 'LUNO', yAxisID: 'yAxis1'}];
      this.price_data_eth.push({ data: WORLD_ADJ_ETH, label: 'WORLD', yAxisID: 'yAxis1'});      
      this.price_data_eth.push({ data: MI_ETH, label: 'MI', yAxisID: 'yAxis2'});      
      this.price_data_eth.push({ data: MI_MA_ETH, label: 'MI MA', yAxisID: 'yAxis2'});      

      this.prices_loaded = true;
      this.loading = false;

    })    

    //Settings and parameters
    this.firestore.collection("portfolios").doc(this.uid).valueChanges().subscribe(portfolio => {
      if(portfolio !== undefined){
        this.portfolio_loaded = true;
        this.portfolio = portfolio
      }
    });
    
    //Orders
    this.firestore.collection('trades',ref => ref.where('uid','==',this.uid).where('complete','==',false).orderBy('time_requested','desc')).valueChanges().subscribe(orders => {
      this.orders = [];
      orders.forEach(o => {this.orders.push(o)});
    })

    //Copy
    this.firestore.collection('copy',ref => ref.where('uid','==',this.uid)).valueChanges().subscribe(copies => {
      this.copy = {};
      this.copy_list = [];
      copies.forEach(o => {this.copy[o['copy']]=o['volume'];this.copy_list.push(o)});
    })    

    //Closed Positions
    this.firestore.collection('positions',ref => ref.where('uid','==',this.uid).where('complete','==',true).orderBy('time_submit','asc').limitToLast(5)).valueChanges().subscribe(positions => {
      this.positions_closed = [];
      positions.forEach(o => {this.positions_closed.push(o)});
      this.positions_closed=this.positions_closed.reverse()
    })  
    
    //Open Positions
    this.firestore.collection('positions',ref => ref.where('uid','==',this.uid).where('complete','==',false)).valueChanges().subscribe(positions => {
      this.positions_waiting = [];
      this.positions_open = [];
      this.positions_vol = 0;
      positions.forEach(o => {
        if(o['status']==0){
          this.positions_waiting.push(o);
        } else {
          this.positions_open.push(o);
        }
        if(o['status']>=2){
          this.positions_vol = this.positions_vol + o['volume']*this.price_luno;
        };
      });
    }) 
    
    //Performance
    this.firestore.collection('performance',ref => ref.orderBy('gain','desc').limit(5)).valueChanges().subscribe(positions => {
      this.performance = [];
      positions.forEach(o => {
        if(o['count']>0){this.performance.push(o)}      
      });
    }) 
    
    this.firestore.collection('performance').doc(this.uid).valueChanges().subscribe(perf => {
      if(perf !== undefined){
        this.my_performance=perf;
        this.my_performance_loaded=true;
        this.positions_index=0;
      }
    }) 
    
    this.firestore.collection('performance_historical',ref => ref.where('uid','==',this.uid).orderBy('end','asc').limitToLast(30)).valueChanges().subscribe(positions => {
      var gains = [];
      var profits = [];
      this.performance_labels = [];
      positions.forEach(o => {
        gains.push(o['gain']);
        profits.push(o['profit']); 
        this.performance_labels.push((new Date(o['end']*1)).toDateString());
      });
      this.performance_data = [{ data: gains, label: 'Gain', yAxisID: 'yAxis1'}];
      this.performance_data.push({ data: profits, label: 'Profit', yAxisID: 'yAxis2'}); 
      this.performance_options['scales'] = {'yAxes': [
        {
            id: 'yAxis1',
            position: 'right'
        },
        {
            id: 'yAxis2',
            position: 'left'
        }
        ]
      }
      this.performance_loaded = true;            
    })     
    
  }

  slack(messageText){    
    var webHook = 'https://hooks.slack.com/services/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';
    const message = {
      text: this.user['email'] + ' -> ' + messageText
    }    
    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      ),
      responseType: 'text' as 'text'
    };    
    this.http.post(webHook, message, options).subscribe();
  }  
  
  position_complete(position){
    this.firestore.collection("positions").doc(position).update({'status':7});
    this.firestore.collection("positions").doc(position).update({'complete':true});
  }
  
  position_close(position){
    this.firestore.collection("positions").doc(position).update({'close':true});
  }

  submit_feedback(){
    var webHook = 'https://hooks.slack.com/services/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';
    const message = {
      text: 'Feedback from ' + this.user['email'] + ': ' + this.feedback
    }    
    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      ),
      responseType: 'text' as 'text'
    };    
    this.http.post(webHook, message, options).subscribe();    
    this.feedback = '';
    this.snack('Submitted');  
  }

  copy_end_2(user){
    var id = this.uid + '_' + user['copy'];
    this.firestore.collection("copy").doc(id).delete().then(d => {
      this.snack('Copying deactivated on');
    })  
  }

  copy_end(user){
    this.dialog.open(DialogCopyEnd, {
      width: '350px',
      data: {volume: 1000, data:user}
    }).afterClosed().subscribe(result => {
      if(result){
        var id = this.uid + '_' + user['uid'];
        this.firestore.collection("copy").doc(id).delete().then(d => {
          this.snack('Copying deactivated on ' + user['email']);
        })
      } 
    });    

  }

  copy_start(user) {
    this.dialog.open(DialogCopy, {
      width: '350px',
      data: {volume: 1000, data:user}
    }).afterClosed().subscribe(result => {
      if(result['volume'] > 250 && this.user_subscription > 0){
        var id = this.uid + '_' + result['data']['uid'];
        this.firestore.collection("copy").doc(id).set({
          'volume':result['volume'],
          'uid':this.uid,
          'copy':result['data']['uid'],
          'displayname':user['displayname']
        }).then(d => {
          this.snack('Trade copying activated with ZAR ' + result['volume']);
        }).catch(e => {
          this.snack(e);
        })
      } else {
        if (result['volume'] > 0 && this.user_subscription > 0){
          this.snack('Volume should be > ZAR 250')
        } else {
          if(this.user_subscription == 0){
            this.snack('Premium subscription required');
            this.tab_index = 3;
          }
        }
      }
    });
  } 
  
  credit_balances_load(uid){
    this.credit_balances = [];
    this.firestore.collection("balances",ref => ref.where('uid','==',uid).orderBy('time','asc').limitToLast(10)).get().subscribe(d => {
      d.forEach(b => {
        this.credit_balances.push(b.data());
      })
    })
  }  

  credit_balances_add(uid,credit_ZAR){
    
    var balance = parseFloat(this.credit_balances[this.credit_balances.length-1]['balance']);
    var time = parseFloat(this.credit_balances[this.credit_balances.length-1]['time']);
    var rate = 0;
    var description = "";
    
    this.firestore.collection("subscriptions", ref => ref.where("uid","==",uid).orderBy("time","asc").limitToLast(1)).get().subscribe(d => {

      var sub = 0;
      d.forEach(subs => {
        sub = subs.data()['subscription']
      })
      
      if(sub == 0){rate = 0; description = "Zero cost for free subscription"};
      if(sub == 1){rate = parseFloat(this.rate1); description = "Cost for Base subscription"};
      if(sub == 2){rate = parseFloat(this.rate2); description = "Cost for Premium subscription"};
      
      var out = rate*((new Date()).getTime() - time)/2635200000; //=1000*60*60*24*30.5
      var current_balance = balance - out;
      
      this.firestore.collection("balances").add({
        'balance':current_balance,
        'description':description,
        'in':0,
        'out':out,
        'time':(new Date()).getTime(),
        'uid':uid
      }).then(d => {
        var new_balance = current_balance*1 + credit_ZAR*1;
        this.firestore.collection("balances").add({
          'balance':new_balance,
          'description':'Credit added',
          'in':credit_ZAR,
          'out':0,
          'time':(new Date()).getTime() + 1,
          'uid':uid
        }).then(d => {
          this.credit_balances_load(uid);
        })

      })

    })
 
  }

}

@Component({
  selector: 'dialog_copy',
  templateUrl: 'dialog_copy.html',
})
export class DialogCopy {

  constructor(public dialogRef: MatDialogRef<DialogCopy>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(result): void {
    this.dialogRef.close(result);
  }  

}

@Component({
  selector: 'dialog_copy_end',
  templateUrl: 'dialog_copy_end.html',
})
export class DialogCopyEnd {

  constructor(public dialogRef: MatDialogRef<DialogCopy>,@Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }

  onNoClick(result): void {
    this.dialogRef.close(result);
  }

  onYesClick(result): void {
    this.dialogRef.close(result);
  }  

}
