import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { auth } from 'firebase/app';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public title      = 'apollocx';
  public loggedin   = false;
  public loggedin_anon   = false;
  public logging_in = false;    
  public uid        = 'false';    
  public email      = 'false';    
  public user       = {};
  public loading    = false;
  public approved   = false;
  public welcome    = false;
  public terms_accepted      = false;
  public user_loaded = false;
  private access = {
    'name_first':'',
    'name_last':'',
    'mobile':'',
  }
  private charts_loaded = false;
  private performance = [];

  //Charts
  private line_colors: Color[]              = [{borderColor: 'black', backgroundColor: 'rgba(255,0,0,0.3)'}];
  private line_type                         = 'line';     
  private price_data: ChartDataSets[]       = [{ data: [], label: 'Prices'}];
  private price_labels: Label[]             = [];
  private luno_options  = {responsive: true, elements: {line: {tension: 0}}, animation: { duration: 0 }, maintainAspectRatio: false};   

  constructor(public router: Router, public dialog: MatDialog, public http:HttpClient, public authenticate: AngularFireAuth, public firestore: AngularFirestore, private _snackBar: MatSnackBar) {
  
    //Get authentication
    this.authenticate.authState.subscribe((user) => {      
      if(user){        
        if(!user.isAnonymous){
          console.log('Signed in gmail.')
          this.loggedin   = true;
          this.loggedin_anon = false;
          this.logging_in = false;   
          this.uid        = user.uid; 
          this.email      = user.email;
          this.user       = user; 
          this.loading    = true;  
          this.slack('Logged in');        
          this.load_user();
        } else {
          console.log('Signed in anon.')
          this.loggedin   = false;
          this.loggedin_anon = true;
          if(!this.charts_loaded){this.load_data()};
        }
      } else {
        this.logging_in = false; 
        this.loggedin = false; 
        this.loading = false; 
        this.terms_accepted = false; 
        this.approved = false;
        this.authenticate.signInAnonymously();
      };      
    }) 

  }  

  load_user(){

    console.log('Loading user data ...')
    this.firestore.collection("users").doc(this.uid).snapshotChanges().subscribe(user => {
      if(this.loggedin){     
        if(user.payload.data() == undefined){
          this.firestore.collection('tasks').add({
            'type':'new_user',
            'uid':this.uid,
            'email':this.email,
            'complete':false,
            'attended':0
          })
        } else {
          
          this.user     = user.payload.data(); 
          this.approved = this.user['approved'];
          
          if(!this.user.hasOwnProperty('approved')){
            this.approved = true;        
            this.firestore.collection("users").doc(this.uid).update({'approved':true});
          }
          
          if(this.user.hasOwnProperty('terms')){
            this.terms_accepted = this.user['terms'];  
            if(!this.terms_accepted){this.loading=false};      
          } else {
            this.firestore.collection("users").doc(this.uid).update({'terms':false});
          }

          this.user_loaded = true; 
          this.loading     = false;

          if(this.terms_accepted){
            this.dashboard()
          } else {
            console.log('Terms not accepted');
            this.onboard();
          };

        }
      }
    })
  } 
  
  load_data(){
    // Price chart data
    this.firestore.collection("prices",ref => ref.orderBy('time').limitToLast(500)).valueChanges().subscribe(points => {
      
      this.price_labels = [];      
      var LUNO = [];
      var WORLD = [];
      var WORLD_LONG = [];
      var WORLD_ADJ = [];
      var LUNO_WORLD = [];      
      var MI = [];
      var MI_MA = [];    
      var counter = 1;
      var USDZAR = 0;
      
      points.forEach(p => {     
        
        if(LUNO.length > 0){
          if(Math.abs(p['BTC_LUNO']*1 - LUNO[LUNO.length-1]) > LUNO[LUNO.length-1]*0.05){
            p['BTC_LUNO'] = LUNO[LUNO.length-1]
          }
          if(Math.abs(p['BTC_WRLD']*1 - WORLD[WORLD.length-1]) > WORLD[WORLD.length-1]*0.05){
            p['BTC_WRLD'] = LUNO[LUNO.length-1]
          } 
          if(Math.abs(p['BTC_WRLD_ADJ']*1 - WORLD_ADJ[WORLD_ADJ.length-1]) > WORLD_ADJ[WORLD_ADJ.length-1]*0.05){
            p['BTC_WRLD_ADJ'] = WORLD_ADJ[WORLD_ADJ.length-1]
          }                    
        }   

        LUNO.push(p['BTC_LUNO']*1);
        WORLD.push(p['BTC_WRLD']*1);
        WORLD_ADJ.push(p['BTC_WRLD_ADJ']*1);
        LUNO_WORLD.push(p['BTC_LUNO']-p['BTC_WRLD_ADJ']);
        WORLD_LONG.push(p['BTC_WRLD']*1);
        MI.push(p['BTC_GEMI_MI']);
        MI_MA.push(p['BTC_GEMI_MI_MA'])    
        USDZAR = p['USDZAR'];

        this.price_labels.push((new Date(p['time'])).toLocaleTimeString());
        counter+=1;  
        
      });

      this.luno_options['scales'] = {'yAxes': [
          {
              id: 'yAxis1',
              position: 'right'
          },
          {
              id: 'yAxis2',
              position: 'left'
          }
      ]
      }   

      this.price_data = [{ data: LUNO, label: 'LUNO', yAxisID: 'yAxis1'}];
      this.price_data.push({ data: WORLD_ADJ, label: 'WORLD', yAxisID: 'yAxis1'});      
      this.price_data.push({ data: MI, label: 'MI', yAxisID: 'yAxis2'});      
      this.price_data.push({ data: MI_MA, label: 'MI MA', yAxisID: 'yAxis2'});      
      this.charts_loaded = true;

    })  
    //Performance
    this.firestore.collection('performance',ref => ref.orderBy('gain','desc').limit(5)).valueChanges().subscribe(positions => {
      this.performance = [];
      positions.forEach(o => {
        if(o['count']>0){this.performance.push(o)}      
      });
    })       
  }

  logout(){
    this.authenticate.signOut().then(d => {
      this.loggedin = false;
      console.log("Signed out");
      this.home();
    })
  }

  login(){
    if(this.loggedin_anon){
      this.authenticate.signOut().then(d => {
        this.authenticate.signInWithPopup(new auth.GoogleAuthProvider()).then(user => {console.log("Signed in with Google");this.loading=true;})       
      })      
    } else {
      this.authenticate.signInWithPopup(new auth.GoogleAuthProvider()).then(user => {console.log("Signed in with Google");this.loading=true;})       
    }
  } 
  
  request_access(){
    this.firestore.collection("users").doc(this.uid).update({
      'access':this.access,
      'access_requested':true
    }).then(d => {
    })
  }  

  snack(message: string) {
    this._snackBar.open(message,"", {duration: 3000});
  }
  
  snack_long(message: string) {
    this._snackBar.open(message,"", {duration: 8000});
  }  
  
  onboard(){
    this.router.navigate(['/onboard']);
  }  

  dashboard(){
    this.router.navigate(['/dashboard']);
  }  

  home(){
    this.router.navigate(['/home']);
  }  
  
  terms(){
    this.router.navigate(['/terms']);
  }  
  
  accept_terms(){
    this.firestore.collection("users").doc(this.uid).update({
      'terms':true,
      'terms_time':(new Date()).getTime()
    }).then(d => {
      this.dashboard();
    })
  } 
  
  slack(messageText){    
    var webHook = 'https://hooks.slack.com/services/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';
    const message = {
      text: this.user['email'] + ' -> ' + messageText
    }    
    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      ),
      responseType: 'text' as 'text'
    };    
    this.http.post(webHook, message, options).subscribe();
  }   

}

